/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onRouteUpdate = ({ location, action }) => {
    // console.log('new pathname', location.pathname)
    // console.log('Action: ', action);

    // Allows scrolling to an anchor on hard refresh or fresh page load
    // Having to set a timeout to wait for React to finish loading the virtual DOM
    if (location && location.hash) {
        setTimeout(() => {
            document.querySelector(location.hash).scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 500)
    }
}

export const shouldUpdateScroll = args => {
    return false;
}